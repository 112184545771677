<template>
  <div class="main webstyles">
    <el-card shadow="never" class="cus-card">
      <el-row>
        <el-col :span="24">
          <div class="static-container animated fadeIn info">
            <div class="static-container-header static-container-header--small">
              <h5 class="static-container-header__title">
                <div class="static-container-header__left" @click="back()">
                  <img src="@/assets/web/image/left-arrow.png" alt="" class="c-icons" />
                  {{ !baseData.id ? $t('task_add') : $t('task_edit') }}
                </div>
                <div class="static-container-header__right"></div>
              </h5>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form
            :model="formData"
            :rules="formRules"
            label-width="100px"
            label-suffix="："
            class="form"
          >
            <el-form-item :label="$t('task_language')">
              <el-radio-group v-model="language">
                <el-radio-button :label="0">{{ $t('en_us') }}</el-radio-button>
                <el-radio-button :label="1">{{ $t('zh_cn') }}</el-radio-button>
                <el-radio-button :label="2">{{ $t('th_th') }}</el-radio-button>
                <el-radio-button :label="3">{{ $t('vn') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('task_title')" prop="title">
              <el-input v-model="formData.title[language]"></el-input>
            </el-form-item>
            <el-form-item :label="$t('task_type')" prop="type">
              <el-radio-group v-model="formData.type">
                <el-radio-button :label="1">{{ $t('task_type_common') }}</el-radio-button>
                <el-radio-button :label="2">{{ $t('task_type_holding') }}</el-radio-button>
                <el-radio-button :label="3">{{ $t('task_type_mass') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('task_platform')" prop="platform">
              <el-radio-group v-model="formData.platform">
                <el-radio-button :label="1">{{ $t('Facebook') }}</el-radio-button>
                <el-radio-button :label="2">{{ $t('Line') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('task_hot')" prop="hot">
              <el-radio-group v-model="formData.hot">
                <el-radio-button :label="0">{{ $t('task_no') }}</el-radio-button>
                <el-radio-button :label="1">{{ $t('task_yes') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('task_number')" prop="amount">
              <el-input
                v-model.number="formData.amount"
                :placeholder="$t('task_integer')"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('task_amount')" prop="price">
              <el-input
                v-model="formData.price"
                :placeholder="$t('task_keep_two_decimals')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('task_priority')" prop="sort">
              <el-input v-model.number="formData.sort" :placeholder="$t('task_integer')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('task_quantity_restriction')" prop="number_limit">
              <el-input
                v-model.number="formData.number_limit"
                :placeholder="$t('task_integer')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('task_rule')" prop="rule">
              <el-input type="textarea" v-model="formData.rule[language]"></el-input>
            </el-form-item>
            <el-form-item :label="$t('task_teaching_picture')" prop="teach_image">
              <Upload
                accept="image/png, image/jpeg"
                :limit="1"
                :headers="{
                  Authorization: token
                }"
                :action="uploadUrl"
                v-model="formData.teach_image[language]"
              ></Upload>
            </el-form-item>
            <el-form-item :label="$t('task_teaching_video')" prop="teach_video">
              <Upload
                accept="video/mp4"
                :limit="1"
                :headers="{
                  Authorization: token
                }"
                :action="uploadUrl"
                v-model="formData.teach_video[language]"
              ></Upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="loading" @click="confirm">{{
                $t('btn_confirm')
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script lang="ts">
import Upload from '@/common/web/upload/index.vue';
import {Options, Vue} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {ElMessage, ElDialog, ElSwitch} from 'element-plus';
import {basic} from '@/config';
import {Server} from '@/services';
@Options({
  components: {
    ElMessage,
    ElDialog,
    ElSwitch,
    Upload
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class Page extends Vue {
  language = 0;
  formData: any = {};
  loading = false;
  formRules = {};
  baseData: {
    id?: number;
  } = {};
  uploadUrl = basic.mainlandUrl + '/api/Common/uploadsTask';
  token = `Bearer ${localStorage.getItem('mainland_token') || ''}`;
  @Watch('checkIndex')
  created() {
    const {params} = this.$route;
    this.baseData = params;
    this.formData = {
      title: params.title ?? ['', '', '', ''],
      type: params.type ?? null,
      platform: params.platform ?? null,
      hot: params.hot ?? null,
      amount: params.amount ?? null,
      price: params.price ?? null,
      sort: params.sort ?? null,
      number_limit: params.number_limit ?? null,
      rule: params.rule ?? ['', '', '', ''],
      teach_image:
        params.teach_image instanceof Array
          ? params.teach_image.map((item: string) =>
              item
                ? [
                    {
                      uid: item,
                      name: item,
                      status: 'done',
                      url: `${basic.mainlandUrl}/${item}`
                    }
                  ]
                : []
            )
          : [[], [], [], []],
      teach_video:
        params.teach_video instanceof Array
          ? params.teach_video.map((item: string) =>
              item
                ? [
                    {
                      uid: item,
                      name: item,
                      status: 'done',
                      url: `${basic.mainlandUrl}/${item.replace('.MP4', '.mp4')}`
                    }
                  ]
                : []
            )
          : [[], [], [], []]
    };
    this.formRules = {
      amount: [{type: 'number', message: this.$t('task_integer')}],
      sort: [{type: 'number', message: this.$t('task_integer')}],
      number_limit: [{type: 'number', message: this.$t('task_integer')}]
    };
  }
  back() {
    this.$router.push({path: '/tasklist'});
  }
  async confirm() {
    this.loading = true;
    const getFile = (item: any) => {
      return item && item.length ? item[0]?.response?.data?.src ?? item[0]?.name : '';
    };
    const {formData, baseData} = this;
    const params = {
      ...formData,
      id: baseData.id,
      teach_image: formData.teach_image.map(getFile),
      teach_video: formData.teach_video.map(getFile)
    };
    const res = await Server.Web.Task[baseData.id ? 'taskEdit' : 'taskAdd']<any>(params);
    if (res instanceof Error) {
      ElMessage.error(res.message);
    } else {
      ElMessage({
        message: baseData.id ? this.$t('ui_change_successful') : this.$t('tip_add_success'),
        type: 'success'
      });
      this.back();
    }
    this.loading = false;
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/element.scss';
.form {
  padding: 20px 100px;
}
.static-container-header {
  margin-bottom: 30px;
}
.static-container-header--small .static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.static-container-header__left {
  display: flex;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
/deep/ .el-textarea__inner {
  background: #f5f5f5;
  border-color: transparent;
}
</style>

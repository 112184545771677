
import Upload from '@/common/web/upload/index.vue';
import {Options, Vue} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {ElMessage, ElDialog, ElSwitch} from 'element-plus';
import {basic} from '@/config';
import {Server} from '@/services';
@Options({
  components: {
    ElMessage,
    ElDialog,
    ElSwitch,
    Upload
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class Page extends Vue {
  language = 0;
  formData: any = {};
  loading = false;
  formRules = {};
  baseData: {
    id?: number;
  } = {};
  uploadUrl = basic.mainlandUrl + '/api/Common/uploadsTask';
  token = `Bearer ${localStorage.getItem('mainland_token') || ''}`;
  @Watch('checkIndex')
  created() {
    const {params} = this.$route;
    this.baseData = params;
    this.formData = {
      title: params.title ?? ['', '', '', ''],
      type: params.type ?? null,
      platform: params.platform ?? null,
      hot: params.hot ?? null,
      amount: params.amount ?? null,
      price: params.price ?? null,
      sort: params.sort ?? null,
      number_limit: params.number_limit ?? null,
      rule: params.rule ?? ['', '', '', ''],
      teach_image:
        params.teach_image instanceof Array
          ? params.teach_image.map((item: string) =>
              item
                ? [
                    {
                      uid: item,
                      name: item,
                      status: 'done',
                      url: `${basic.mainlandUrl}/${item}`
                    }
                  ]
                : []
            )
          : [[], [], [], []],
      teach_video:
        params.teach_video instanceof Array
          ? params.teach_video.map((item: string) =>
              item
                ? [
                    {
                      uid: item,
                      name: item,
                      status: 'done',
                      url: `${basic.mainlandUrl}/${item.replace('.MP4', '.mp4')}`
                    }
                  ]
                : []
            )
          : [[], [], [], []]
    };
    this.formRules = {
      amount: [{type: 'number', message: this.$t('task_integer')}],
      sort: [{type: 'number', message: this.$t('task_integer')}],
      number_limit: [{type: 'number', message: this.$t('task_integer')}]
    };
  }
  back() {
    this.$router.push({path: '/tasklist'});
  }
  async confirm() {
    this.loading = true;
    const getFile = (item: any) => {
      return item && item.length ? item[0]?.response?.data?.src ?? item[0]?.name : '';
    };
    const {formData, baseData} = this;
    const params = {
      ...formData,
      id: baseData.id,
      teach_image: formData.teach_image.map(getFile),
      teach_video: formData.teach_video.map(getFile)
    };
    const res = await Server.Web.Task[baseData.id ? 'taskEdit' : 'taskAdd']<any>(params);
    if (res instanceof Error) {
      ElMessage.error(res.message);
    } else {
      ElMessage({
        message: baseData.id ? this.$t('ui_change_successful') : this.$t('tip_add_success'),
        type: 'success'
      });
      this.back();
    }
    this.loading = false;
  }
}
